import { memo, useEffect, useState } from 'react';
import {
  BurgerButton,
  MainMenu,
  MainMenuButton,
  MainMenuIcon,
  FlexSpacer,
  FlexCell,
  DropdownMenuButton,
  DropdownMenuBody,
  Switch,
  MainMenuAvatar,
  GlobalMenu,
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  FlexRow,
  Panel,
  Text,
  Button,
} from '@epam/promo';
import {
  MainMenuCustomElement,
  Dropdown,
  ScrollBars,
} from '@epam/uui-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as question } from '../../../assets/svg/question.svg';
import MainLogo from '../../../assets/png/garage-logo.png';
import UserService from 'services/api/user/userService';
import eventManagementService from 'services/api/event-managment/eventManagementService';
import { fetchImage } from '../../../component/Avatar/utils';
import './styles.scss';
import {
  isCanAccessPermissionsPage,
  isUserWithEventOrganizerRole,
} from '../../../utils/rolesUtils';
import { useUserInfo } from '../../../services/auth-client/user-info-provider';
import { CreateProjectButton } from '../../../component/Buttons/CreateProjectButton';
import { REACT_APP_ENV } from 'services/auth-client';
import { isUserWithEditorRole } from '../../../utils/rolesUtils';
import { IModal, useUuiContext } from '@epam/uui';

interface Props {
  editorSwitch: boolean;
  setEditorSwitch: (editorSwitch: boolean) => void;
  eventOrganizerSwitch: boolean;
  setEventOrganizerSwitch: (eventOrganizerSwitch: boolean) => void;
}

const Header = ({
  editorSwitch,
  setEditorSwitch,
  eventOrganizerSwitch,
  setEventOrganizerSwitch,
}: Props) => {
  const { uuiModals } = useUuiContext();
  const [image, setImage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const { userInfo, logOut } = useUserInfo();
  const helpLink = 'https://epa.ms/AboutGarage';
  const isEditor = isUserWithEditorRole(userInfo);
  const isEventEditor = isUserWithEventOrganizerRole(userInfo);
  const [eventNames, setEventNames] = useState<string[]>([]);
  const navigateToPermissions = () => {
    navigate('/permissions');
  };
  const useGlobalMenu = (src: string) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  const globalMenuScriptLink =
    REACT_APP_ENV === 'production'
      ? 'https://menu.epam.com/scripts/menu.js?app=garage-lab'
      : `https://globalmenu-stage.epm-ppa.projects.epam.com/scripts/menu.js?app=garage-lab`;
  useEffect(() => {
    useGlobalMenu(globalMenuScriptLink);
  }, []);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const getUserProfile = async (imgURL: string) => {
    const img = await fetchImage(
      imgURL,
      `${userInfo?.firstName.charAt(0)}${userInfo?.lastName.charAt(0)}`
    );

    setImage(img as string);
  };

  useEffect(() => {
    const imgURL = UserService.getUserProfilePicture();
    if (!imgURL) return;
    getUserProfile(imgURL);
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    if (!isEditor) {
      localStorage.removeItem('editorSwitch');
      setEditorSwitch(false);
    }
    if (!isEventEditor) {
      localStorage.removeItem('eventOrganizerSwitch');
      setEventOrganizerSwitch(false);
    }
  }, [userInfo]);

  useEffect(() => {
    const getEventNames = async () => {
      const eventNames = await eventManagementService.getEventNames();
      setEventNames(eventNames as unknown as string[]);
    };
    if (!editorSwitch) {
      getEventNames();
    }
  }, [editorSwitch]);

  const NAVIGATION_ROUTERS: { label: string; to: string; hidden?: boolean }[] =
    [
      {
        label: 'My Projects',
        to: '/my-projects',
      },
      {
        label: 'Dashboard',
        to: '/dashboard',
      },
      {
        label: 'Opportunities',
        to: '/opportunities',
      },
      {
        label: 'Showroom',
        to: '/showroom',
      },
      {
        label: 'Events',
        to: '/events',
      },
    ];

  const navigations = NAVIGATION_ROUTERS.filter((nav) => !nav.hidden);

  const renderBurger = () => (
    <ScrollBars>
      {navigations.map((nav) => (
        <BurgerButton
          key={nav.label}
          caption={nav.label}
          onClick={() => navigate(nav.to)}
        />
      ))}

      <BurgerButton caption="Help" href={helpLink} target="_blank" />
      {isCanAccessPermissionsPage(userInfo?.roles || []) && (
        <BurgerButton caption="Permissions" onClick={navigateToPermissions} />
      )}
      <BurgerButton caption="Log out" onClick={logOut} />
    </ScrollBars>
  );

  const EditorModeModal = (modalProps: IModal<string>) => (
    <ModalBlocker
      disallowClickOutside
      blockerShadow="dark"
      {...modalProps}
      disableCloseByEsc={true}
    >
      <ModalWindow>
        <Panel background="white">
          <ModalHeader
            borderBottom
            title="Switch on to Event Editor mode?"
            onClose={() => modalProps.abort()}
          />
          <FlexRow cx="modal-content">
            <Text size="36">
              {' '}
              {
                'With the Event Editor mode on, you will be able to manage all the projects linked to the following Events:'
              }{' '}
            </Text>
          </FlexRow>
          {eventNames.length > 0 &&
            eventNames.map((eventName, index) => {
              return (
                <FlexRow padding="24">
                  <Text key={index}> {eventName}</Text>
                </FlexRow>
              );
            })}
          <ModalFooter borderTop>
            <FlexSpacer />
            <Button
              color="gray50"
              fill="white"
              caption="Cancel"
              onClick={() => {
                modalProps.abort();
              }}
            />
            <Button
              color="blue"
              caption="Switch"
              onClick={() => {
                setEditorSwitch(true);
                localStorage.setItem('editorSwitch', true.toString());
                modalProps.success('');
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );

  const switchValueChangeHandler = () => {
    if (editorSwitch) {
      setEditorSwitch(false);
      localStorage.setItem('editorSwitch', false.toString());
    } else {
      uuiModals.show((props) => <EditorModeModal {...props} />);
    }
  };

  return (
    <FlexCell grow={1} cx="header">
      <MainMenu renderBurger={renderBurger}>
        <MainMenuCustomElement priority={100500} estimatedWidth={200}>
          <Link to="/dashboard" className="nav-item">
            <FlexCell width={200} cx="header__main-logo">
              <img
                src={MainLogo}
                alt="Garage dashboard logo"
                style={{ height: '30px' }}
              />
            </FlexCell>
          </Link>
        </MainMenuCustomElement>

        {navigations.map((nav, index) => (
          <MainMenuCustomElement priority={3} estimatedWidth={130} key={index}>
            <Link to={nav.to} className="nav-item">
              <MainMenuButton
                collapseToMore
                caption={nav.label}
                isLinkActive={activeLink.includes(nav.to)}
              />
            </Link>
          </MainMenuCustomElement>
        ))}

        <FlexSpacer priority={100500} />
        {isEditor && (
          <MainMenuCustomElement priority={10} estimatedWidth={60}>
            <Switch
              label={
                <span
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: '400',
                    marginRight: '24px',
                  }}
                >
                  {editorSwitch ? 'Editor Mode ON' : 'Editor Mode OFF'}
                </span>
              }
              value={editorSwitch}
              onValueChange={switchValueChangeHandler}
            />
          </MainMenuCustomElement>
        )}

        {isEventEditor && (
          <MainMenuCustomElement priority={10} estimatedWidth={80}>
            <Switch
              label={
                <span
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: '400',
                    marginRight: '24px',
                  }}
                >
                  Event Organizer Mode {eventOrganizerSwitch ? 'ON' : 'OFF'}
                </span>
              }
              value={eventOrganizerSwitch}
              onValueChange={() => {
                setEventOrganizerSwitch(!eventOrganizerSwitch);
                localStorage.setItem(
                  'eventOrganizerSwitch',
                  (!eventOrganizerSwitch).toString()
                );
              }}
            />
          </MainMenuCustomElement>
        )}

        {isEventEditor && (
          <MainMenuCustomElement priority={10} estimatedWidth={80}>
            <Switch
              label={
                <span
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: '400',
                    marginRight: '24px',
                  }}
                >
                  Event Organizer Mode {eventOrganizerSwitch ? 'ON' : 'OFF'}
                </span>
              }
              value={eventOrganizerSwitch}
              onValueChange={() => {
                setEventOrganizerSwitch(!eventOrganizerSwitch);
                localStorage.setItem(
                  'eventOrganizerSwitch',
                  (!eventOrganizerSwitch).toString()
                );
              }}
            />
          </MainMenuCustomElement>
        )}

        <MainMenuCustomElement priority={11} estimatedWidth={128}>
          <FlexCell width={128} minWidth={128}>
            <CreateProjectButton hasIcon />
          </FlexCell>
        </MainMenuCustomElement>
        <MainMenuIcon
          href={helpLink}
          target="_blank"
          icon={question}
          onClick={() => null}
          priority={10}
          estimatedWidth={60}
        />
        <MainMenuCustomElement priority={10} estimatedWidth={180}>
          <Dropdown
            renderTarget={(props) => (
              <MainMenuAvatar avatarUrl={image} isDropdown {...props} />
            )}
            renderBody={() => (
              <DropdownMenuBody cx={'header__user-menu'} onClose={() => null}>
                {isCanAccessPermissionsPage(userInfo?.roles || []) && (
                  <DropdownMenuButton
                    caption="Permissions"
                    onClick={navigateToPermissions}
                  />
                )}
                <DropdownMenuButton caption="Log out" onClick={logOut} />
              </DropdownMenuBody>
            )}
            placement="bottom-end"
          />
        </MainMenuCustomElement>

        <MainMenuCustomElement priority={10}>
          <button className="global-menu-style">
            <GlobalMenu />
          </button>
        </MainMenuCustomElement>
      </MainMenu>
    </FlexCell>
  );
};

export default memo(Header);
